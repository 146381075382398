<template>
  <div class="pay-reward-desc">
    <van-tabs v-model="tabIndex" background="#f5f5f5">
      <van-tab title="每日充值">
        <div class="content">
          <section class="_editor">
            <p>
              <span style="color: #ff0000;"
                ><strong
                  ><span style="font-size: 15px;"
                    >网站充值奖励自动发放，但是程序运行难免出错，如果在游戏系统内没有收到奖励邮件，请手动到网站领取！！！</span
                  ></strong
                ></span
              >
            </p>
            <p>
              <span style="color: #ff0000;"
                ><strong
                  ><span style="font-size: 15px; color: #ff0000;"
                    ><br /></span></strong
              ></span>
            </p>
            <p>
              <span style="color: #ff0000;"
                ><strong
                  ><span style="font-size: 15px; color: #ff0000;"
                    >每日充值奖励过了12点之后，将会重置，所以必须当天充值当天领完，否则一律无法找回！！！</span
                  ></strong
                ></span
              >
            </p>
            <p>
              <span style="color: #ff0000;"
                ><strong
                  ><span style="font-size: 15px; color: #ff0000;"
                    ><br /></span></strong
              ></span>
            </p>
            <p>
              <span style="font-size: 15px; color: #ff0000;"
                ><strong
                  >举例：假如一天内充值了400元，即可包含每日30元、100元、200元、400元的所有奖励，并非只有400元的奖励！</strong
                ></span
              >
            </p>
            <p>
              <strong
                ><span style="font-size: 15px;"><br /></span
              ></strong>
            </p>
            <p>
              <strong><span style="font-size: 15px;">30元</span></strong
              ><span style="font-size: 15px;"
                >：<span style="letter-spacing: 0.034em;"
                  >宝石提升符“2，绑定元宝680*50，灵魂碎片·青&quot;10，蓝田玉令*</span
                ><span style="letter-spacing: 0.034em;"
                  >2，地煞强化精华*20</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong><span style="font-size: 15px;">100元</span></strong
              ><span style="font-size: 15px;"
                >：<span style="letter-spacing: 0.034em; font-size: 15px;"
                  >宝石提升符*3，地煞强化精华*40，灵魂碎片·青*15，蓝田玉令*</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >3，铸纹血玉*5</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  ><br /></span
                ><br
              /></span>
            </p>
            <p>
              <strong><span style="font-size: 15px;">200元</span></strong
              ><span style="font-size: 15px;"
                >：<span style="letter-spacing: 0.034em; font-size: 15px;"
                  >雕龙玉令*2，小喇叭*20，天罡强化精华*20
                  ，龙魂元神精睫*2，</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >暗器修炼石*20</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  ><br /></span
                ><br
              /></span>
            </p>
            <p>
              <strong><span style="font-size: 15px;">400元</span></strong
              ><span style="font-size: 15px;"
                >：<span style="letter-spacing: 0.034em; font-size: 15px;"
                  >阵灵石*5，玄凤元神精魄*2，铸纹血玉*7，千淬神玉*5，龙魂元</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >神精瑰*4</span
                ></span
              >
            </p>
            <p>
              <span style="letter-spacing: 0.034em; font-size: 15px;"
                ><br
              /></span>
            </p>
            <p>
              <strong
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >1000元</span
                ></strong
              ><span style="font-size: 15px;"
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >：</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >三星护心镜宝箱*1，灵魂碎片·青*50，溅射*3，援护*3，衰老*3，</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"></span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"></span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  ><br /></span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  ><br /></span
                ><strong>2000元</strong>：<span
                  style="letter-spacing: 0.034em; font-size: 15px;"
                  >妖虎元神精魄*4，龙魂元神精魄*4，鹏元神宝箱*4，麒麟元神</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >精魄*4，玄风元神精魄*4</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong><span style="font-size: 15px;">3000元</span></strong
              ><span style="font-size: 15px;"
                >：100征战功勋*5，灵魂碎片·青*20，紫髓玉令*3，散气*5，溅射*5</span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >4000元</span
                ></strong
              ><span style="letter-spacing: 0.034em; font-size: 15px;"
                >：馄鹏元神宝箱*5，灵魂碎片.赤*20，元神丹*3，馄鹏玉令“1，铸纹血玉*10</span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong><span style="font-size: 15px;">5000元</span></strong
              ><span style="font-size: 15px;"
                >：<span style="letter-spacing: 0.034em; font-size: 15px;"
                  >元神丹*10，重楼之麟碎片*1，重楼之纹碎片*1，重楼之锋碎片*1，</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >重楼之印碎片*1</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong><span style="font-size: 15px;">6000元</span></strong
              ><span style="font-size: 15px;"
                >：<span style="letter-spacing: 0.034em; font-size: 15px;"
                  >麒麟元神精魄*5，鲲鹏元神精魄*5，龙魂元神精魄*5，妖虎元神</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >精魄</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;">*</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >5，玄凤元神精魄</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;">*</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >5</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong><span style="font-size: 15px;">7000元</span></strong
              ><span style="font-size: 15px;"
                >：<span style="letter-spacing: 0.034em; font-size: 15px;"
                  >宝石升符*30，麒麟元神精魄*6，元神丹*5，紫髓玉令*10，新莽</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >神符*50</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong><span style="font-size: 15px;">8000元</span></strong
              ><span style="font-size: 15px;"
                >：<span style="letter-spacing: 0.034em; font-size: 15px;"
                  >680绑定元宝*300，龙魂元神精魄*6，玄凤元神精魄*6，麒麟元</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >神精魄*6，妖虎元神精魄*6</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong><span style="font-size: 15px;">9000元</span></strong
              ><span style="font-size: 15px;"
                >：援护*5，衰老*5，天命神符*1，眼鹏玉令*5，铸纹血玉*20<span
                  style="letter-spacing: 0.034em; font-size: 15px;"
                ></span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"></span
              ></span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong><span style="font-size: 15px;">10000元</span></strong
              ><span style="font-size: 15px;"
                >：<span style="letter-spacing: 0.034em; font-size: 15px;"
                  >重楼之麟碎片*1，重楼之纹碎片*1，重楼之印碎片*1，重楼之锋</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >碎片*1</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong><span style="font-size: 15px;">12000元</span></strong
              ><span style="font-size: 15px;"
                >：<span style="letter-spacing: 0.034em; font-size: 15px;"
                  >680绑定元宝*300，元神丹*20，90级保星符*20，暗器修炼石*60，</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >天命神符*1</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"></span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"></span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  ><br /></span
                ><br
              /></span>
            </p>
            <p>
              <strong><span style="font-size: 15px;">15000元</span></strong
              ><span style="font-size: 15px;"
                >：<span style="letter-spacing: 0.034em; font-size: 15px;"
                  >90级保星符*30，千淬神玉*20，铸纹血玉*30，阵灵石*30，雕龙</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >玉令*10</span
                ></span
              >
            </p>
            <p>
              <span style="font-size: 15px;"><br /></span>
            </p>
            <p>
              <strong><span style="font-size: 15px;">20000元</span></strong
              ><span style="font-size: 15px;"
                >：麒<span style="letter-spacing: 0.034em; font-size: 15px;"
                  >麟元神精魄*10，鲲鹏元神精魄*10，龙魂元神精魄*10，妖虎元</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >神精魄*10，玄凤元神精魄*10</span
                ></span
              >
            </p>
            <p>
              <span style="font-size: 15px;"><br /></span>
            </p>
            <p>
              <strong><span style="font-size: 15px;">25000元</span></strong
              ><span style="font-size: 15px;"
                >：<span style="letter-spacing: 0.034em; font-size: 15px;"
                  >灵魂碎片·赤*40，灵魂碎片·橙*50，天命神符*1，一箱宝珠*50，</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >30珍珠*20</span
                ></span
              >
            </p>
            <p>
              <span style="font-size: 15px;"><br /></span>
            </p>
            <p>
              <span style="font-size: 15px;"></span>
            </p>
            <p>
              <strong><span style="font-size: 15px;">30000元</span></strong
              ><span style="font-size: 15px;"
                >：重楼之麟碎片*2，重楼之纹碎片*2，重楼之印碎片*2，重楼之锋碎片*2，90级保星符*100<span
                  style="letter-spacing: 0.034em; font-size: 15px;"
                ></span
              ></span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong><span style="font-size: 15px;">40000元</span></strong
              ><span style="font-size: 15px;"
                >：重楼之麟碎片*3，重楼之纹碎片*3，重楼之印碎片*3，重楼之锋碎片*3，新莽神符*100</span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong><span style="font-size: 15px;">45000元</span></strong
              ><span style="font-size: 15px;"
                >：麒麟元神精魄*30，鲲鹏元神精魄*30，龙魂元神精魄*30，妖虎元神精魄*30，玄凤元神精魄*30</span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong><span style="font-size: 15px;">50000元</span></strong
              ><span style="font-size: 15px;"
                >：重楼之麟碎片*3，重楼之纹碎片*3，重楼之印碎片*3，重楼之锋碎片*3，重楼之华*1</span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
          </section>
          <section class="_editor">
            <p>
              <br />
            </p>
          </section>
        </div>
      </van-tab>
      <van-tab title="累计充值">
        <div class="content">
          <section class="_editor">
            <p>
              <span style="color: #ff0000;"
                ><strong
                  ><span style="font-size: 15px;"
                    >网站充值奖励自动发放，但是程序运行难免出错，如果在游戏系统内没有收到奖励邮件，请手动到网站领取！！！</span
                  ></strong
                ></span
              >
            </p>
            <p>
              <span style="color: #ff0000;"
                ><strong
                  ><span style="font-size: 15px; color: #ff0000;"
                    ><br /></span></strong
              ></span>
            </p>
            <p>
              <span style="font-size: 15px; color: #ff0000;"
                ><strong
                  >举例：假如总共充值了1000元，即可包含累计150元、累计500元、累计1000元的所有奖励，并非只有1000元的奖励！</strong
                ></span
              >
            </p>
            <p>
              <strong
                ><span style="font-size: 15px;"><br /></span
              ></strong>
            </p>
            <p>
              <span style="font-size: 15px;"
                >150元：<span style="letter-spacing: 0.034em;"
                  >绑定元宝680*100，60级保星符*10，宝石提升符*10，灵魂碎片</span
                ><span style="letter-spacing: 0.034em;"
                  >青*50，龙魂精魄*5</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="font-size: 15px;"
                >500元：<span style="letter-spacing: 0.034em; font-size: 15px;"
                  >龙魂精魄*10，宝石提升符*15，灵魂碎片赤*100，新莽神符*50</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >，蓝田玉令*20</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="font-size: 15px;"
                >1000元：<span style="letter-spacing: 0.034em; font-size: 15px;"
                  >紫髓玉令*40，龙魂精魄*10，嗜血*35，妖虎精魄*10，100征战</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >功勋*10</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="font-size: 15px;"
                >2000元：<span style="letter-spacing: 0.034em; font-size: 15px;"
                  >龙魂精魂*10，宝石提升符*20，天罡强化精华*150，暗器修炼</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >石*20，九天灵珀*100</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="font-size: 15px;"
                >5000元：<span style="letter-spacing: 0.034em; font-size: 15px;"
                  >宝石提升符*25，铸纹血玉*150，灵魂碎片·橙*150，龙庭鲤跃(永</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >久)*1，铁甲暴龙1</span
                ></span
              >
            </p>
            <p>
              <span style="letter-spacing: 0.034em; font-size: 15px;"
                ><br
              /></span>
            </p>
            <p>
              <span style="font-size: 15px;"
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >10000元：</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >反施咒*33，化功咒*33，三星护心镜宝箱*20，阵灵石*50，青</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"></span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >焰煞虎(永久)*1</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="font-size: 15px;"
                >15000元：魑魅<span
                  style="letter-spacing: 0.034em; font-size: 15px;"
                  >诀*33，天机诀*33，御守诀*33，灵魂碎片·橙*100，鲲鹏</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >玉令*30</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="font-size: 15px;"
                >20000元：<span
                  style="letter-spacing: 0.034em; font-size: 15px;"
                  >重楼之麟碎片*2，重楼之纹碎片*2，重楼之印碎片*2，重楼之锋</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >碎片*2，金霞风火轮 (永久)*1</span
                ></span
              >
            </p>
            <p>
              <span style="letter-spacing: 0.034em; font-size: 15px;"
                ><br
              /></span>
            </p>
            <p>
              <span style="font-size: 15px;"
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >25000元：</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >衰败咒*33，煞心咒*33，腐朽咒*33，宝石提升符*50，铸纹血</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"></span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >玉*250</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="font-size: 15px;"
                >30000元：<span
                  style="letter-spacing: 0.034em; font-size: 15px;"
                  >重楼之麟碎片*2，重楼之纹碎片*2，重楼之印碎片*2，重楼之锋</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >碎片*2，冰火玄凤(永久)*1</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="font-size: 15px;"
                >35000元：<span
                  style="letter-spacing: 0.034em; font-size: 15px;"
                  >麒麟元神精魄*50，鲲鹏元神精魄*50，龙魂元神精魄*50，妖虎</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >元神精魄*50，玄凤元神精魄*50</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="font-size: 15px;"
                >40000元：<span
                  style="letter-spacing: 0.034em; font-size: 15px;"
                  >进化诀*33，启迪决*33，18880绑定元宝*200，重楼之灵*1，焰</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >甲墨麟(永久)*1</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="font-size: 15px;"
                >45000元：<span
                  style="letter-spacing: 0.034em; font-size: 15px;"
                  >铸纹血玉*250，灵魂碎片·橙*250，宝石提升符*50，九天灵珀</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;">*</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >200，至尊强化精华*200</span
                ></span
              >
            </p>
            <p>
              <span style="letter-spacing: 0.034em; font-size: 15px;"
                ><br
              /></span>
            </p>
            <p>
              <span style="font-size: 15px;"
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >50000元：</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >重楼玉*1，龙魂精魄*60 ，妖虎精魄*60 ，玄凤精魄*60
                  ，御剑龙</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"></span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >神（永久）*1</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="font-size: 15px;"
                >60000元：<span
                  style="letter-spacing: 0.034em; font-size: 15px;"
                  >100征战功勋*100，鲲鹏玉令*50，紫薇灵魄*50，长生灵魄*100，</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >青玄灵魄*200</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="font-size: 15px;"
                >70000元：<span
                  style="letter-spacing: 0.034em; font-size: 15px;"
                  >麒麟元神精魄*70，鲲鹏元神精魄*70，龙魂元神精魄*70，妖虎</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >元神精魄*70，玄凤元神精魄*70</span
                ></span
              >
            </p>
            <p>
              <span style="letter-spacing: 0.034em; font-size: 15px;"
                ><br
              /></span>
            </p>
            <p>
              <span style="font-size: 15px;"
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >80000元：</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >无崖子拜帖碎片*200，顿悟丹*20，易容匣*20，太玄经*20，柔</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"></span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >丝索*20</span
                ></span
              >
            </p>
            <p>
              <span style="letter-spacing: 0.034em; font-size: 15px;"
                ><br
              /></span>
            </p>
            <p>
              <span style="font-size: 15px;"
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >90000元：</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >麒麟元神精魄*90，鲲鹏元神精魄*90，龙魂元神精魄*90，妖虎元</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"></span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >神精魄*90，玄凤元神精魄*90</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="font-size: 15px;"
                >100000元：<span
                  style="letter-spacing: 0.034em; font-size: 15px;"
                  >六脉谱*20，段氏佩*20，镇南玉*20，寰宇鸾凤(永久)*1，重楼</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >玉*1</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="font-size: 15px;"
                >110000元：麒麟<span
                  style="letter-spacing: 0.034em; font-size: 15px;"
                  >元神精魄*99，鲲鹏元神精魄*99，龙魂元神精魄*99，妖虎元</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >神精魄*99，玄凤元神精魄*99</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="font-size: 15px;"
                >120000元：<span
                  style="letter-spacing: 0.034em; font-size: 15px;"
                  >金翼魔龙(永久)*1，御雷苍龙(永久)*1，煜耀圣虎(永久)*1，九天</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >玄女(永久)*1，神霄百战甲(永久)*1</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="font-size: 15px;"
                >130000元：<span
                  style="letter-spacing: 0.034em; font-size: 15px;"
                  >麒麟元神精魄*99，鲲鹏元神精魄*99，龙魂元神精魄*99，妖虎元</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >神精魄*99，玄凤元神精魄*99</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="font-size: 15px;"
                >140000元：<span
                  style="letter-spacing: 0.034em; font-size: 15px;"
                  >麒麟元神精魄*99，鲲鹏元神精魄*99，龙魂元神精魄*99，妖虎元</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >神精魄*9，,玄凤元神精魄*99</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="font-size: 15px;"
                >150000元：<span
                  style="letter-spacing: 0.034em; font-size: 15px;"
                  >重楼玉*1，90级保星符*500，阵灵石*200，18880绑定元宝*666</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >，天命神符*3</span
                ></span
              >
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="font-size: 15px;"
                >200000元：<span
                  style="letter-spacing: 0.034em; font-size: 15px;"
                  >麒麟元神精魄*255，鲲鹏元神精魄*255，龙魂元神精魄*255，重</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >楼玉*1，玄凤元神精魄*255</span
                ></span
              >
            </p>
            <p>
              <span style="letter-spacing: 0.034em; font-size: 15px;"
                ><br
              /></span>
            </p>
            <p>
              <span style="font-size: 15px;"
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >250000元：</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >重楼玉*1，90级保星符*999，18880绑定元宝*999，龙魂元神精</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"></span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >魄*255，鲤鹏元神精魄*255</span
                ></span
              >
            </p>
            <p>
              <span style="letter-spacing: 0.034em; font-size: 15px;"
                ><br
              /></span>
            </p>
            <p>
              <span style="font-size: 15px;"
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >300000元：</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"></span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >18880绑定元宝*999，龙魂元神精魄*255，鲲鹏元神精魄*255，至</span
                ><span style="letter-spacing: 0.034em; font-size: 15px;"
                  >尊强化精华*999，玄凤元神精魄*255</span
                ></span
              >
            </p>
          </section>
          <section class="_editor">
            <p>
              <br />
            </p>
          </section>
        </div>
      </van-tab>

      <van-tab title="充值攻略">
        <div class="content">请咨询群主</div>
      </van-tab>
    </van-tabs>
    <div class="to-pay">
      <van-button block round type="danger" @click="toGetPayReward"
        >前往领取奖励</van-button
      >
    </div>
  </div>
</template>
<script>
import { Tabs, Tab, Button } from "vant";

export default {
  name: "PayRewardDesc",
  components: {
    "van-tabs": Tabs,
    "van-tab": Tab,
    "van-button": Button
  },
  data() {
    return {
      tabIndex: 0
    };
  },
  methods: {
    toGetPayReward() {
      window.location.href = "http://8.218.82.68:16002/#/reward";
    }
  }
};
</script>
<style lang="less" scoped>
.content {
  padding: 20px 20px 100px 20px;
}

.to-pay {
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
}
</style>
